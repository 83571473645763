import React, { useState } from 'react'
import { Box, Layer, Button } from 'grommet';

const VideoModal = ({ shift }) => {
  const [show, setShow] = useState(false);

  return (
    <Box fill>
      <Button
        label="Quick tutorial 🚀"
        onClick={() => setShow(true)}
        margin={{ top: 'medium' }}
        style={{ textAlign: 'center' }}
        alignSelf={shift ? 'center' : 'stretch'}
      />
      {show && (
        <Layer
          responsive={false}
          onEsc={() => setShow(false)}
          onClickOutside={() => setShow(false)}
        >
          <Box width='800px'>
            <div
              className="video"
              style={{
                width: '100%',
                position: "relative",
                paddingBottom: "56.25%" /* 16:9 */,
                height: 0
              }}
            >
              <iframe
                title="1TT tuto"
                style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                src={`https://www.youtube.com/embed/pRmymjrDs9w`}
                frameBorder="0"
              />
            </div>
          </Box>
        </Layer>
      )}
    </Box>
  );
}

export default VideoModal