import React from 'react'
import { Box, Text } from 'grommet';

import InstallButton from './installButton';
import VideoModal from './videoModal';

const Steps = ({ shift }) => (
  <Box align={shift ? 'center' : 'start'}>
    <Text textAlign={shift ? 'center' : 'start'}>
      <Text weight='bold'>Step 1. </Text>
      Click on the install button to get the browser extension.
    </Text>
    <InstallButton
      margin={{ top: 'small' }}
      alignSelf={shift ? 'center' : 'stretch'}
    />

    <Text textAlign={shift ? 'center' : 'start'} margin={{ top: 'medium' }}>
      <Text weight='bold'>Step 2. </Text>
      Open a product from 1688.com, taobao.com or tmall.com.
    </Text>

    <Text textAlign={shift ? 'center' : 'start'} margin={{ top: 'medium' }}>
      <Text weight='bold'>Step 3. </Text>
      Open the app window and start downloading!.
              </Text>

    <Text textAlign={shift ? 'center' : 'start'} margin={{ top: 'medium' }}>
      <Text weight='bold'>That's it!. </Text>
      Images and videos are downloaded to your computer!
    </Text>

    <VideoModal shift={shift} />
  </Box>
);

export default Steps;