import React from 'react'
import { graphql } from 'gatsby'
import { Box, Heading, Text, ResponsiveContext } from 'grommet';
import styled from 'styled-components';

import Layout from '../components/layout'
import SEO from '../components/seo'
import Steps from '../components/steps';
import HeroImage from '../components/heroImage';

const HeroImageBox = styled(Box)`
  @media (max-width:1126px) {
    height: 700px;
  }
  @media (max-width:920px) {
    height: 600px;
  }
  @media (max-width:768px) {
    height: 500px;
  }
  @media (max-width:650px) {
    height: 450px;
  }
  @media (max-width:600px) {
    height: 400px;
  }
  @media (max-width:500px) {
    height: 350px;
  }
  @media (max-width:400px) {
    height: 230px;
  }
`

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Save 1688, Taobao and Tmall product images and videos"
          description="Thanks to AliSave you can download product images from AliExpress & Alibaba in one click with the BEST QUALITY possible! It's fast, easy and free."
        />

        <Box
          align='center'
          pad='medium'
          background='#fafafd'
        >

          <Box width='1000px' pad={{ bottom: 'large', top: 'medium' }} align='center'>
            <Heading level={1} size='small' margin='none' textAlign='center'>
              SAVE IMAGES & VIDEOS FROM <b>1688</b>, <b>Taobao</b> and <b>Tmall</b>!
            </Heading>
            <Text textAlign='center'>
              Save time and get the best image quality with 1TT extension
            </Text>
          </Box>

          <ResponsiveContext.Consumer>
            {(size) => {
              const shift = (size === 'small' || size === 'hero');
              return (
                <Box
                  width='1000px'
                  direction={shift ? 'column-reverse' : 'row'}
                  pad={{ top: 'small', bottom: shift ? 'small' : 'large' }}
                >
                  <HeroImageBox
                    width={shift ? '100%' : '65%'}
                    style={{ position: 'relative' }}
                    justify='center'
                    align='center'
                  >
                    <HeroImage shift={shift} />
                  </HeroImageBox>
                  <Box
                    width={shift ? '100%' : '35%'}
                    align={shift ? 'center' : 'start'}
                    pad={{ left: 'medium', bottom: shift ? 'medium' : 'large' }}
                  >
                    <Steps shift={shift} />
                  </Box>
                </Box>

              );
            }}
          </ResponsiveContext.Consumer>
        </Box>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
