import React from 'react'
import { StaticQuery, graphql } from "gatsby"
// import Image from 'gatsby-image';
import { Image } from 'grommet';

import browserImage from '../../content/assets/browser.png'

const HeroImage = ({ queryData, shift }) => {
  return (
    <Image
      src={browserImage}
      fit='contain'
      // fixed={queryData.browser.childImageSharp.fixed}
      // alt='AliSave hero tuto image'
      style={{
        position: shift ? 'initial' : 'absolute',
        right: shift ? 'auto' : '0px',
        maxWidth: shift ? '100%' : '701px',
      }}
    />
  );
}

const HeroImageContainer = (props) => {
  return (
    <StaticQuery
      query={heroImageQuery}
      render={data => {
        return <HeroImage queryData={data} {...props} />
      }}
    />
  );
}

const heroImageQuery = graphql`
  query HeroImageQuery {
    browser: file(absolutePath: { regex: "/browser.png/" }) {
      childImageSharp {
        fixed(width: 701, height: 501) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

export default HeroImageContainer