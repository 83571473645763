import React from 'react';
import { Link } from 'gatsby';
import { Box, Anchor, ResponsiveContext } from 'grommet';

import InstallButton from './installButton';

const Footer = () => {
  return (
    <Box
      border={{
        color: 'rgba(09, 0, 0, 0.08)',
        side: 'top'
      }}
      align='center'
      pad='medium'
      as='footer'
    >
      <Box
        width='1000px'
        justify='between'
        align='center'
        direction='row'
      >
        <ResponsiveContext.Consumer>
          {size => (
            <Box
              direction='row'
              gap='large'
              justify={size === 'small' ? 'around' : 'start'}
              width='100%'
            >
              <Link to='/terms'>
                <Anchor as='span' color='dark-3' size={size === 'small' ? 'xsmall' : 'small'}>
                  {size === 'small' ? 'Terms' : 'Terms of Service'}
                </Anchor>
              </Link>
              <Link to='/privacy'>
                <Anchor as='span' color='dark-3' size={size === 'small' ? 'xsmall' : 'small'}>
                  {size === 'small' ? 'Privacy' : 'Privacy Policy'}
                </Anchor>
              </Link>
              {/* <Link to='/blog'>
                <Anchor as='span' color='dark-3' size={size === 'small' ? 'xsmall' : 'small'}>
                  Blog
                </Anchor>
              </Link> */}
              {/* <Link to='/updates'>
                <Anchor as='span' color='dark-3' size={size === 'small' ? 'xsmall' : 'small'}>
                  Updates
                </Anchor>
              </Link> */}
            </Box>
          )}
        </ResponsiveContext.Consumer>

        <ResponsiveContext.Consumer>
          {size => {
            return size === 'small' ? null : (
              <Box direction='row' gap='small'>
                <InstallButton/>
              </Box>
            )
          }}
        </ResponsiveContext.Consumer>
      </Box>
    </Box>
  );
};

export default Footer;