import React from 'react'
import { Grommet } from 'grommet';

import { theme } from '../utils/theme'
import Header from './header';
import Footer from './footer';

class HomeLayout extends React.Component {
  render() {
    const { children } = this.props

    return (
      <Grommet theme={theme}>
        <Header />
        <main>{children}</main>
        <Footer />
      </Grommet>
    )
  }
}

export default HomeLayout
